import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout/Layout';
import {Link} from 'gatsby';
import ImageGallery from 'react-image-gallery';
import ProductPageIcon from './../../components/ProductPageIcon';
import beraterBild from '../../images/berater_04.webp'
import schiebetuer_01 from '../../images/schiebetuer_05_1600.webp';
import schiebetuer_thumb_01 from '../../images/schiebetuer_05_400.webp';
import schiebetuer_02 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_querformat_1600.webp';
import schiebetuer_thumb_02 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_querformat_400.webp';
import schiebetuer_03 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_sonderformat_1600.webp';
import schiebetuer_thumb_03 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_sonderformat_400.webp';
import schiebetuer_04 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_querformat_individuell_1600.webp';
import schiebetuer_thumb_04 from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_querformat_individuell_400.webp';
import apotheker from '../../images/automatische_schiebetuer_apotheker_800.webp';
import durchreiche from '../../images/automatische_schiebetuer_apotheke_mit_durchreiche.webp';
import abholfach from '../../images/automatische_schiebetuer_apotheke_mit_abholfaecher.webp';
import abholfachPin from '../../images/automatische_schiebetuer_apotheke_mit_notdienstanlage_pin.webp';
import {trackScreenView} from '../../functions/analytics';
import phone from '../../svg/phone.svg';

const Apotheke = () => {

  const carouselConfig = {
    showThumbnails: true,
    showFullscreenButton: false,
    showPlayButton: false,
    showBullets: false,
    autoPlay: false,
    showNav: false,
    slideInterval: 3000,
    slideDuration: 600,
  }

  const context = {
features: [
      //'autschr',
      'barrierefreiheit',
      //'brandschutz',
      //'breakOut',
      'personenfrequenz',
      //'din18650',
      'energieeinsparung',
      'notStrom',
      'einbruchhemmung',
      'design',
      //'platzsparend',
      //'transparenz',
      'zutrittskontrolle',
      'fluchtweg',
      'modernisierung',
    ],
    carouselImages: [
      {
        original: schiebetuer_01,
        thumbnail: schiebetuer_thumb_01,
        originalAlt: 'Automatische Schiebetür Apotheke mit Logo beklebt'
      },
      {
        original: schiebetuer_02,
        thumbnail: schiebetuer_thumb_02,
        originalAlt: 'Apotheke mit Automatiktür und Notdienstanlage im Querformat'
      },
      {
        original: schiebetuer_03,
        thumbnail: schiebetuer_thumb_03,
        originalAlt: 'Automatische Schiebtür für Apotheke mit Notdienstanlage im Hochformat'
      },
      {
        original: schiebetuer_04,
        thumbnail: schiebetuer_thumb_04,
        originalAlt: 'Apotheke mit elektrischer Schiebetür und individueller Notdienstanlage mit Infomonitor'
      },
    ],
    configurator: true,
  }

  return (
    <div className="application">
      <Helmet>
        <title>Automatische Schiebetüren für Apotheken | Automatiktür24</title>
        <meta name="description" content="Automatische elektrische Schiebetüren für Apotheken.
        Barrierefreier Eingang, Nachtdurchreiche in Glasschiebetür, Abholfach im Glas, Notdienstanlage mit Schiebetür" />
        <meta charSet="utf-8" />
        <html lang="de" />
      </Helmet>
      <Layout>

        <section className="pt-10">
          <div className="container mx-auto px-8 lg:flex">
            <div className="w-full">
              <p className="text-lg lg:text-xl mb-3 font-light">
                Barrierefreie elektrische Schiebetüren mit Abholfach, Notdienstanlage und mehr
              </p>
              <h1 className="text-2xl lg:text-3xl xl:text-5xl font-bold leading-none">
                Automatische Schiebetüren für Apotheken
              </h1>
            </div>
          </div>


          <div className="container mx-auto px-8 lg:flex">

            <div className="lg:w-1/2">
              <div className="pb-10 mt-12">
                <ImageGallery items={context.carouselImages} {...carouselConfig}/>
              </div>
            </div>

            <div className="lg:w-1/2 lg:pl-5 xl:pl-10 md:flex lg:flex">

              <div className="pb-10 md:w-1/2 lg:w-2/3">
                <p className="text-lg lg:text-xl font-light mt-12 mb-4">
                  Vorteile und Möglichkeiten <br/> auf einen Blick
                </p>
                <ul>
                  {context.features.map(feature => (
                    <li key={feature}>
                      <ProductPageIcon attribute={feature} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:w-1/2 lg:w-1/3">
                {(context.configurator === true) ?
                  <div className="lg:w-48 mb-6">
                    <Link to="/automatiktuer-konfigurator/"
                          className="bg-blue-500 hover:bg-blue-600 text-white hover:text-white
                          py-4 text-center block rounded shadow-xl"> Automatiktür für Apotheke konfigurieren</Link>
                  </div>
                  : ''
                }
                <div className="lg:w-48 mb-6">
                  <Link to="/kontakt/" className="bg-gray-400 hover:bg-gray-500 text-white hover:text-white py-4 text-center block rounded shadow-xl">Kontakt aufnehmen</Link>
                </div>

                <div className="w-48 rounded overflow-hidden shadow-xl">
                  <img className="w-full" src={beraterBild} alt="Angebot einfordern" />
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">Max Brunner</div>
                    <p className="text-gray-700 text-sm">
                      Individuelle Beratung
                      <br/>
                      Tel. <a href="tel:+4975245092939">07524 / 509 29 39</a>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Automatiktüren für Apotheken
            </h2>
            <p className="text-xl font-light">Jetzt auch mit Notdienstanlage und Abholfach</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2">
              <p>Der Einbau von Automatiktüren verbessert Kundenfreundlichkeit einer Apotheke immens.
              Dabei müssen bei der Fassadenplanung funktionale und optische Aspekte gekonnt miteinander verbunden werden.</p>

              <p className="mt-6">Insbesondere die Anforderungen an die Barrierefreiheit, Optik und Einbruchsicherheit sind hoch.
              Hinzu kommen Aspekte wie Energieeffizienz oder Fluchtwegsicherheit.
              Zudem stillen automatische Türen für die Apotheke das mittlerweile allgegenwärte Hygienebedürfnis der Apotheken-Kunden.
              </p>

              <p className="mt-6">Wenn Sie Apotheker sind, dann vermitteln wir Ihnen nachfolgend wertvolle Information bei der Suche
                nach der richtigen Automatiktür für Apotheken.</p>

              <p className="mt-6 font-bold">Leise Öffnung</p>
              <p className="mt-3">Durch die hochwertige Verarbeitung öffnet sich die Türen schnell und leise.
                Dieser Umstand kommt den Mitarbeitern und den Kunden zu Gute. Kunden merken kaum den Zutritt ins Gebäude.
                Zudem empfinden Mitarbeiter das permanente Öffnen der Türen nicht als störend.</p>

              <p className="mt-6 font-bold">Hygiene durch kontakfreies Öffnen</p>
              <p className="mt-3">Das automatische Aufgehen sorgt für eine kontaktlose Öffnung.
                Mit anderen Worten minimiert sich das Risiko für die Erkrankung mit Bakterien und Viren.
                Diese Rahmenbedingungen gewinnen in Zeiten von Epidemien und Pandemien an Bedeutung.
                Zu den typischen Apothekenkunden gehören Menschen mit einem schwachen Immunsystem.
                Diese Menschen sind schutzbedürftig.</p>

              <p className="mt-6 font-bold">Barrierefreiheit</p>
              <p className="mt-3">Eine Automatiktür für die Apotheke schafft einen barrierefreien Zugang.
                Denn gehbehinderte Menschen benötigen ausreichend Platz beim Betreten eines Gebäudes.
                Der Alltag besteht schließlich aus vielen Hürden für Rollstuhlfahrer.
                Apotheken sollen hier eine positive Ausnahme bilden.
                Dies fordert die Apothekenbetriebsordnung bereits seit 2012, um die Barrierefreiheit voranzutreiben.</p>



            </div>
            <div className="lg:w-1/2">
              <div className="flex flex-col lg:mx-6 mt-8 lg:mt-0 max-w-500">
                <div
                  className="relative overflow-hidden flex-none w-full h-48 md:h-64 rounded-t-xl mx-auto">
                  <img className="absolute object-cover object-top w-full h-full" src={apotheker} alt="" />
                </div>
                <div className="p-4 md:p-8 text-center md:text-left space-y-4 bg-gray-100 rounded-b-xl text-base">
                  <blockquote>
                    <p className="font-semibold text-gray-600">
                      &#8220;Ganz ehrlich - auch früher wollte schon niemand die Türklinke einer Apotheke anfassen.
                      Spätestens seit der Pandemie gibt es für mich jedoch keine Alternative zur Automatiktür.&#8221;
                    </p>
                  </blockquote>
                  <figcaption className="font-medium">
                    <div className="text-blue-600">
                      Leopold M.
                    </div>
                    <div className="text-gray-500">
                      Apotheker
                    </div>
                  </figcaption>
                </div>
              </div>

              <div className="my-8 sm:m-10 w-72">
                <Link to="/automatiktuer-konfigurator/"
                      className="border border-blue-500 py-3 px-4 text-center block rounded shadow-xl"> Automatiktür online konfigurieren</Link>
              </div>
            </div>
          </div>



          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Automatische Schiebetür mit seitlicher Verglasung und Notdienstanlage
            </h2>
            <p className="text-xl font-light">Elegant. Praktisch. Informativ. Modern.</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-2">
              <p>Für die Funktionalität einer automatische Automatiktür Apotheke kommen ein oder zwei öffnende Türflügel zum Einsatz.
                Zusätzlich dazu ist eine Antriebseinheit vonnöten. Je nach örtlicher Gegebenheit passen wir diese an das Rahmenelement oder
                an das umliegende Mauerwerk an. Auf Grund der individuellen Gegebenheiten bieten wir spezifische Lösungen für Ihre Apotheke vor Ort an.</p>

              <p className="mt-6">Eine Automatiktür mit Notdienstanlage erweitert die erwähnten Vorzüge durch die Möglichkeit,
                einen 24h-Service für die Kunden anzubieten. Dieser Erweiterung macht ein Aufbruch des Mauerwerks obsolet.
                Je nach Bedarf lassen sich Briefkasten, Sprechanlage, Informationsmonitor oder Abholfach kombinieren.
                Auch ein individueller Aufdruck ist möglich, z.B. der Name ihrer Apotheke, Inhaberinfos oder Hausnummer.
              </p>
              <p className="mt-6">Sprechen Sie uns an, wenn Sie bereits konkrete Vorstellungen für die Modernisierung oder den Umbau Ihrer Apotheke haben.</p>
            </div>
            <div className="lg:w-1/2">
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row lg:mx-6 mt-8 lg:mt-0 max-w-400 md:max-w-full">
                <div
                  className="relative overflow-hidden flex-none w-full md:w-80 lg:w-full xl:w-80 h-96 xl:h-112 rounded-t-xl
                  md:rounded-none md:rounded-l-xl lg:rounded-none lg:rounded-t-xl xl:rounded-none xl:rounded-l-xl mx-auto border-2 border-blue-700">
                  <img className="absolute object-cover object-top w-full h-full" src={durchreiche} alt="" />
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-blue-700 rounded-b-xl md:rounded-none md:rounded-r-xl
                lg:rounded-none lg:rounded-b-xl xl:rounded-none xl:rounded-r-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Apotheken-Notdienstanlage
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Die neben der Schiebetür flach ins Glas eingelassene Apotheken-Notdienstanlage bietet Briefkasten,
                      Sprechanlage und dynamisch einstellbares Informationsdisplay. <br/><br/>
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>


          <div className="container mt-20 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Abholfächer für Medikamente neben der automatische Schiebetür
            </h2>
            <p className="text-xl font-light">Bieten Sie Ihren Kunden Premium Service</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-1">
              <p>In der Festverglasung neben der elektrischen Schiebetür der Apotheke lassen sich nicht nur Notdienstanlage oder Durchreiche positionieren.
                Mit cleveren Abholfächern bieten Sie Ihren Kunden Service der Extraklasse.
                Mit PIN Feld können Sie Ihren Kunden einen einfachen Self-Service ermöglichen.
              </p>

              <p className="mt-6">Der leuchtender Ruftaster und die Sprechstelle sind so vorbereitet,
                dass vorhandene oder neue Module einbaut werden können. Damit ist eine Rufumleitung auf ein beliebiges Endgerät möglich.
              </p>

              <p className="mt-6">
                Alle Komponenten zusammen landen in einem eleganten maßgefertigtem Edelstahlgehäuse. Hoch- oder Querformat. Auch Sonderformate sind möglich.</p>
            </div>
            <div className="lg:w-1/2 order-2">
              <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row lg:mx-6 mt-8 lg:mt-0 max-w-400 md:max-w-full">
                <div
                  className="relative overflow-hidden flex-none w-full md:w-80 lg:w-full xl:w-80 h-96 xl:h-112 rounded-t-xl
                  md:rounded-none md:rounded-l-xl lg:rounded-none lg:rounded-t-xl xl:rounded-none xl:rounded-l-xl mx-auto border-2 border-yellow-700">
                  <img className="absolute object-cover object-top w-full h-full" src={abholfach} alt="" />
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-yellow-700 rounded-b-xl md:rounded-none md:rounded-r-xl
                lg:rounded-none lg:rounded-b-xl xl:rounded-none xl:rounded-r-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Apotheken Abholfächer
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Abholfächer erlauben dem Kunden eine 24/7 Abholung der Medikamente. Clever kombiniert mit PIN Eingabefeld,
                      Infomonitor, Klingel und Repzept-Briefkasten.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>



          <div className="container mt-14 mb-8 mx-auto px-8">
            <h2 className="lg:text-2xl font-semibold">
              Apotheke und Automatiktür
            </h2>
            <p className="text-xl font-light">Die elektrische Schiebetür als Aushängeschild</p>
          </div>

          <div className="container mx-auto px-8 lg:flex">
            <div className="lg:w-1/2 order-2">
              <div>
                <div className="pb-3 font-bold">Vorteile auf einen Blick:</div>
                <ul className="list-disc pl-6">
                  <li>Barrierefreiheit</li>
                  <li>hygienische Lösung</li>
                  <li>moderne Optik</li>
                  <li>Durchlässigkeit von Sonnenlicht</li>
                  <li>energieeffiziente Lösung</li>
                  <li>geräuscharme Möglichkeit des Öffnens</li>
                  <li>leichte Bauweise</li>
                  <li>Mit Informationssystem / Notdienstanlage kombinierbar</li>
                </ul>
              </div>

              <div className="mt-6">
                <div className="pb-3 font-bold">Komplettpaket für die Erfordernisse vor Ort</div>
                <p>
                  Wir decken alle Facetten für die Belange von Apothekern ab.
                  Automatiktür Notdienstanlage, Abholfach oder Einbruchschutz können wir bei Bedarf als geschlossenes System anbieten.
                  Eine elektrische Schiebetür für Ihre Apotheke ist langfristig auf Grund der überzeugenden Vorteile eine lohnende Invesition.
                  Nutzen Sie unseren praktischen Online-Konfigurator, um umgehend die passende Tür zu finden.
                </p>
              </div>

              <div className="mt-12 w-72">
                <div className="border border-blue-500 py-3 px-4 justify-center flex rounded shadow-xl h-12 mb-6">
                  <a className="text-sm flex" href="tel:+4975245092939" onClick={() => trackScreenView("Phone Apotheke")}><img src={phone} alt="Telefon" className="text-blue-600 h-5"/> 07524 / 509 29 39</a>
                </div>

                <div className="mb-6">
                  <Link to="/automatiktuer-konfigurator/"
                        className="border border-blue-500 py-3 px-4 text-center block rounded shadow-xl"> Automatiktür online konfigurieren</Link>
                </div>
              </div>

            </div>
            <div className="lg:w-1/2 order-1">
              <div className="flex flex-col lg:mx-6 mt-8 lg:mt-0 max-w-500">
                <div
                  className="relative overflow-hidden flex-none w-full h-64 sm:h-80 rounded-t-xl mx-auto border-2 border-green-700">
                  <img className="absolute object-cover object-top w-full h-full" src={abholfachPin} alt="" />
                </div>
                <div className="p-4 md:p-8 text-left space-y-4 bg-green-700 rounded-b-xl">
                  <blockquote>
                    <p className="text-base font-bold text-white">
                      Automatiktür24 Komplettpaket
                    </p>
                    <p className="text-md font-light text-white mt-4">
                      Planung, Montage, Wartung und Service.<br/>
                      Wir sind Ihr Rundum-Sorglos Partner. Egal ob Neu- oder Umbau, Modernisierung der bestehenden Anlage.<br/><br/>
                      Neben Erweiterungen wie Wärmeschleier oder Notdienstanlage
                      beraten wir Sie auch zu Themen wie Förderung, Einbruchsschutz, oder Risikobewertung.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>



        </section>

      </Layout>
    </div>
  );
}

export default Apotheke

